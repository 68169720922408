import React from "react";
import { useLocation } from "react-router-dom";

const { useEffect, useState } = React;

const FinancingOptions = ({ location, business, media }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div
      className={`section ${
        location !== "/financing" ? "mt-10" : "mt-28 items-center content-wrap"
      } flex flex-col gap-10 mx-auto`}
    >
      <h1 className="text-skin-base text-4xl font-black">Financing Options</h1>
      {media.isMedium ? (
        <div className="flex gap-10 flex-wrap justify-center">
          {business.financing_options.map((option, index) => {
            return (
              <div className="option-container " key={index}>
                <h1 className="plan-no">Plan No. {option.plan_no}</h1>
                <div className="bg-skin-primary p-3 text-skin-base rounded">
                  <h2 className="option-title font-bold">{option.type}</h2>
                  <h2 className="option-title">{option.description}</h2>
                </div>
                <div className="">
                  <h2 className="option-title">Payment Factor: {option.payment_factor}</h2>
                </div>
                <div className="  ">
                  <h2 className="option-title">Discount Rate: {option.discount_rate}%</h2>
                </div>
                <div className="">
                  <label className="table-label">Optional Restrictions:</label>
                  <h2 className="option-title whitespace-pre-line">{option.optional_restrictions}</h2>
                </div >
          
                  <a 
                  href="https://www.mysynchrony.com/mmc/M9218857200"
                  target="_blank"
                  rel="noreferrer"
                  className="apply-button-md text-center text-skin-base p-6">Apply Now</a>
      
              </div>
            );
          })}
        </div>
      ) : (
        <div className="table-container">
          <table className="shadow-inner">
            <thead>
              <tr className="bg-skin-secondary text-skin-inverted text-base ">
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Type
                </th>
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Description
                </th>
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Plan No.
                </th>
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Payment Factor
                </th>
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Discount Rate
                </th>
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Optional Restrictions
                </th>
                <th className="apply-box p-3 table-text text-left border-slate-50 border-2"></th>
              </tr>
            </thead>
            <tbody>
              {business.financing_options.map((option, index) => {
                return (
                  <tr
                    key={index}
                    className={
                      index % 2 === 0 ? "bg-skin-primary" : "bg-slate-200"
                    }
                  >
                    <td className="p-3 table-text text-left">
                      <div className="type-content-table">{option.type}</div>
                    </td>
                    <td className="p-3 table-text text-left">
                      {option.description}
                    </td>
                    <td className="p-3 table-text text-left">
                      {option.plan_no}
                    </td>
                    <td className="p-3 table-text text-left">
                      {option.payment_factor}%
                    </td>
                    <td className="p-3 table-text text-left">
                      {option.discount_rate}%
                    </td>
                    <td className="p-3 table-text text-left whitespace-pre-line">
                      {option.optional_restrictions}
                    </td>
                    <td className="p-3 table-text text-left">
                      <a
                        href="https://www.mysynchrony.com/mmc/M9218857200"
                        rel="noreferrer"
                        className="apply-button"
                      >
                        Apply Now!
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FinancingOptions;
