import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { useState, useEffect, useRef } = React;

const AdminLoginPage = ({ updateUser }) => {

  const [login, setlogin] = useState(false);
  const [er, setEr] = useState(null);

  const redirect = useNavigate();
  const username = useRef();
  const password = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setEr(null)
    axios.post('/admin/login', {}, {
      params: {
        username: username.current.value,
        password: password.current.value
      }
    })
    .then(res => {
      if (res.status === 200 && res.data) {
        setlogin(true);
        updateUser(res.data);
        redirect('/dashboard');
      }
    })
    .catch(err => {
      setEr(err.response.data);
    })
  }

  return (
    <div className="admin-login-page">
      <input ref={username} type="text" placeholder="Username" />
      <input ref={password} type="password" placeholder="Password" />
      <button
        onClick={handleSubmit}
      >Submit</button>
      {er && <p className="text-red-500">{er}</p>}
    </div>
  )
}

export default AdminLoginPage;