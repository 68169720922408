import React from "react";
import axios from "axios";

const { useState, useEffect, useRef } = React;

const ServiceEntry = ({ service, i, updateList, removeService, type }) => {
  return (
    <div
      key={i}
      onClick={() => {
        removeService(i, type);
      }}
      className="service-admin"
    >
      {service}
    </div>
  );
};

export default ServiceEntry;
