import React from "react";
import axios from "axios";
import ProductEntry from "./ProductEntry.jsx";

const { useState, useEffect, useRef } = React;

const AdminProducts = ({ list, getBusinessDetails }) => {
  const [editedList, setEditedList] = useState(list);
  const [showRevertButton, setShowRevertButton] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [deletedItems, setDeletedItems] = useState([]);
  const [newProduct, setnewProduct] = useState({
    image: "",
    type: "package_unit",
    description: "",
    model: [],
    price: "",
  });

  let imageRef = useRef();

  const updateList = (newProduct, i) => {
    let newList = [...editedList];
    newList[i] = newProduct;
    setEditedList(newList);
    setShowRevertButton(true);
  };

  const handleRevert = () => {
    setEditedList(list);
    location.reload();
  };

  const handlePhotoChange = (e) => {
    let file = e.target.files[0];
    setCurrentImage(file);
  };

  const handlePhotoUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("photo", currentImage);
    axios.post("/admin/image-upload-product", formData).then((res) => {
      setnewProduct({ ...newProduct, image: res.data });
    });
  };

  const addProduct = () => {
    setEditedList([...editedList, newProduct]);
    setnewProduct({
      image: "",
      type: "package_unit",
      description: "",
      model: [],
      price: "",
    });
    setShowRevertButton(true);
  };

  const removeOption = (i) => {
    let newList = [...editedList];
    let deletedProduct = newList.splice(i, 1);
    setDeletedItems([...deletedItems, deletedProduct[0]]);
    setEditedList(newList);
    setShowRevertButton(true);
  };

  const saveChanges = () => {
    axios
      .post("/admin/update-products", { products: editedList, deletedItems: deletedItems })
      .then((res) => {
        getBusinessDetails();
        setShowRevertButton(false);
        setDeletedItems([]);
      });
  };

  return (
    <div className="admin-page-container">
      <div className="admin-products">
        <h1 className="text-2xl font-bold">Products</h1>
        <div id="admin-product-container" className="updates-admin-container">
          <table className="table shadow-inner">
            <thead>
              <tr className="bg-skin-secondary text-skin-inverted text-base ">
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Image
                </th>
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Type
                </th>
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Models (separate by commas)
                </th>
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Description
                </th>
                <th className="p-3 table-text text-left border-slate-50 border-2">
                  Price
                </th>
                <th className="apply-box p-3 table-text text-left border-slate-50 border-2"></th>
                <th className="apply-box p-3 table-text text-left border-slate-50 border-2"></th>
              </tr>
            </thead>
            <tbody>
              {editedList.map((product, i) => {
                return (
                  <ProductEntry
                    key={i}
                    product={product}
                    index={i}
                    getBusinessDetails={getBusinessDetails}
                    removeOption={removeOption}
                    updateList={updateList}
                  />
                );
              })}
            </tbody>
          </table>
        

        <table className="shadow-inner" style={{ width: "100%" }}>
          <thead>
            <tr className="bg-skin-secondary text-skin-inverted text-base ">
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Image
              </th>
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Type
              </th>
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Models (separate with commas)
              </th>
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Description
              </th>
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Price
              </th>
              <th className="apply-box p-3 table-text text-left border-slate-50 border-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-skin-primary">
              <td className="p-3 table-text text-left">
                <div className="type-content-table">
                  <form
                    encType="multipart/form-data"
                    className="flex space-between"
                  >
                    <input
                      className="file-input"
                      type="file"
                      accept=".png, .jpeg, .jpg"
                      name="photo"
                      ref={imageRef}
                      onChange={handlePhotoChange}
                    />
                    <input
                      className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
                      type="submit"
                      value="Upload"
                      onClick={handlePhotoUpload}
                    />
                  </form>
                </div>
              </td>
              <td className="p-3 table-text text-left">
                <div>
                  <select
                    className="table-select"
                    value={newProduct.type}
                    onChange={(e) => {
                      setnewProduct({
                        ...newProduct,
                        type: e.target.value,
                      });
                    }}
                  >
                    <option value="package_unit">Package Unit</option>
                    <option value="split_systems">Split Systems</option>
                  </select>
                </div>
              </td>
              <td className="p-3 table-text text-left">
                <textarea
                  className="table-textarea"
                  type="textarea"
                  value={newProduct.model}
                  onChange={(e) => {
                    setnewProduct({
                      ...newProduct,
                      model: e.target.value.split(",")
                    });
                  }}
                ></textarea>
              </td>
              <td className="p-3 table-text text-left">
                <textarea
                  className="table-textarea"
                  type="textarea"
                  value={newProduct.description}
                  onChange={(e) => {
                    setnewProduct({
                      ...newProduct,
                      description: e.target.value,
                    });
                  }}
                ></textarea>
              </td>
              <td className="p-3 table-text text-left">
                <textarea
                  className="table-textarea"
                  type="textarea"
                  value={newProduct.price}
                  onChange={(e) => {
                    setnewProduct({
                      ...newProduct,
                      price: e.target.value,
                    });
                  }}
                ></textarea>
              </td>
              <td className="p-3 table-text text-left">
                <a
                  target="_blank"
                  className="icon-container fin-icon add-icon"
                  onClick={() => {
                    addProduct();
                  }}
                >
                  Add
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          onClick={() => {
            saveChanges();
          }}
          className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
        >
          Save Changes
        </button>
        {showRevertButton ? (
          <button
            className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
            onClick={() => {
              handleRevert();
            }}
          >
            Revert Changes
          </button>
        ) : null}
      </div>
      </div>
    </div>
  );
};

export default AdminProducts;
