import React from "react";
import { MdPhone } from "react-icons/md";

const HomeCarousel = ({ business, media }) => {
  return (
    <div
      style={{
        backgroundImage: `url(./assets/headerPictures/${business.header_image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxShadow: "inset 0 0 400px rgba(0,0,0,0.8)",
      }}
      className="head bg-skin-accent relative"
    >
      <div className="title text-skin-inverted font-bold font-regular  ">
        <img className="logo" src="./assets/ar_logo-01.svg"></img>
        <h1 className="company-name">AR HEATING & COOLING LLC</h1>
      </div>
      <div className="right-container">
        <div className="button-head">
          <h2 className="text-skin-inverted ">
            El Paso&apos;s Premier HVAC Company
          </h2>
        </div>
        <a
          href={`tel:${business.formatted_phone_number}`}
          className="call-button items-center drop-shadow-md"
        >
          <div className="circle">
            <MdPhone className="phone-icon text-skin-base font-bold rounded-lg" />
          </div>
          <div className="text-skin-base font-bold rounded-lg">
            CALL NOW TO GET A QUOTE!{" "}
          </div>
        </a>

        <div className="button-head">
          <h2 className="text-skin-inverted ">
            {business.formatted_phone_number}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default HomeCarousel;
