import React from "react";

const { useEffect } = React;

const ServicesContainer = ({ business, location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`section ${location !== '/services' ? 'mt-10' : 'mt-28 content-wrap'} flex flex-col gap-10 mx-auto`}>
      <h1 className="text-skin-base text-4xl font-black">Services</h1>
      <div className="flex flex-col gap-10">
        <div>
          <ul className="services">
            {business.services.normal_services.map((service, index) => {
              return <li className="service" key={index}>{service}</li>;
            })}
          </ul>
        </div>
        <div>
          <ul className="services">
          {business.services.hvac_services.map((service, index) => {
              return <li className="service" key={index}>{service}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServicesContainer;
