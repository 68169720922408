import React from "react";

const { useState } = React;

const ProductEntry = ({ product }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="product-entry">
      <div className="models-wrap">
        <h1 className="text-skin-base text-2xl font-black">{product.model.length > 1 ? 'Models:':'Model:'}</h1>
        {product.model.map((model, i) => (<div key={i}>{model}</div>))}
      </div>
      <div className="spacer">

      </div>
      <div >
        <h1 className="text-skin-base text-2xl font-black">Description</h1>
        <div>{product.description}</div>
      </div>
    </div>
  );
};

export default ProductEntry;
