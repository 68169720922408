import React from "react";
import ProductList from './ProductList.jsx';
const { useState } = React;


const ProductsContainer = ({setModal}) => {


  return (
    <div
      className={`section ${
        location !== "/products" ? "mt-10" : "mt-28 content-wrap"
      } flex flex-col gap-10 mx-auto`}
    >
      <div className="flex flex-row items-center gap-4">
        <h1 className="text-skin-base text-4xl font-black">Products</h1>
      </div>
      <div className="products-container flex">
        <div className="sub-product-container flex flex-col gap-10 shadow">
          <div className="flex flex-col product-sub-head gap-2">
            <h1 className="text-skin-inverted font-black">Package Units</h1>
            <div
              onClick={() => setModal('package_units')}
              className="view-products-button">View Products</div>
          </div>
          <div className="img-wrap">
            <img id="pu-img" className="img-product" src="./assets/package-unit.png" alt="package-unit-image"/>
          </div>
        </div>
        <div className="sub-product-container flex flex-col gap-10 shadow">
        <div className="flex flex-col product-sub-head gap-2">
            <h1 className="text-skin-inverted font-black">Split Systems</h1>
            <div
              onClick={() => setModal('split_systems')}
              className="view-products-button">View Products</div>
          </div>
          <div className="img-wrap">
            <img id="ss-img" className="img-product" src="./assets/split-systems.png" alt="package-unit-image"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsContainer;
