import React from "react";
import { BsFillTrashFill } from "react-icons/bs";
import { AiFillEdit, AiFillCheckCircle } from "react-icons/ai";

const { useState, useEffect, useRef } = React;

const AdminUpdateEntry = ({ update, index, handleDelete, deleteUpdate, saveEdits }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(update.title);
  const [currentBody, setCurrentBody] = useState(update.body);
  const [currentImage, setCurrentImage] = useState(update.image);

  const titleRef = useRef();
  const bodyRef = useRef();
  const imageRef = useRef();

  const handleEdits = (i) => {
    let newUpdate = {
      image: imageRef.current.value.length > 0 ? imageRef.current.value : null,
      title: titleRef.current.value,
      body: bodyRef.current.value,
      date: new Date().toISOString(),
    };

    saveEdits(i, newUpdate);
    setIsEditing(!isEditing);
  };

  return (
    <div className="update-admin">
      <div
        onClick={() => {
          handleDelete(index);
        }}
        id="trash-icon"
        className="icon-container"
      >
        <BsFillTrashFill />
      </div>
      <div
        onClick={() => {
          if (isEditing) {
            handleEdits(index);
          } else {
            setIsEditing(!isEditing);
          }
        }}
        id="edit-icon"
        className="icon-container"
      >
        {!isEditing ? <AiFillEdit /> : <AiFillCheckCircle />}
      </div>
      <div>
        <h1 className="admin-labels">index: {index}</h1>
      </div>
      <div>
        <h1 className="admin-labels">image</h1>
        <div className="img-container-admin">
          {update.image !== null ? (
            <img className={`card-img`} src={update.image.includes('https') ? update.image : `./assets/updatePictures/${update.image}`} alt={update.title} />
          ) : (
            <img
              className="card-img"
              src="./assets/update-default-01.png"
              alt={update.title}
            />
          )}
          {!isEditing ? null : (
            <input
              type="text"
              ref={imageRef}
              value={currentImage}
              onChange={(e) => {
                setCurrentImage(e.target.value);
              }}
            />
          )}
        </div>
      </div>
      <div>
        <h1 className="admin-labels">title:</h1>
        {!isEditing ? (
          <p>{currentTitle}</p>
        ) : (
          <input
            type="text"
            ref={titleRef}
            value={currentTitle}
            onChange={(e) => {
              setCurrentTitle(e.target.value);
            }}
          />
        )}
      </div>
      <div>
        <h1 className="admin-labels">body:</h1>
        {!isEditing ? (
          <p>{currentBody}</p>
        ) : (
          <textarea
            rows="5"
            cols="50"
            ref={bodyRef}
            value={currentBody}
            onChange={(e) => {
              setCurrentBody(e.target.value);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AdminUpdateEntry;
