import React from "react";
import { Link } from "react-router-dom";
import { MdPhone } from 'react-icons/md';

const Footer = ({ media }) => {
  return (
    <footer className="mx-auto">
      <div className="footer-wrap flex flex-wrap mx-auto p-6 justify-center gap-8 items-center">
        <div className="footer-logo text-skin-inverted font-bold font-regular  ">
          <img className="small-logo" src="./assets/ar_logo-01.svg"></img>
          <h1 className="small-company-name">AR HEATING & COOLING LLC</h1>
        </div>
        {!media.isMobile &&  <div className="seperator"></div>}
        <div className="footer-list flex flex-col gap-2">
          <Link to="/">Home</Link>
          <Link to="/updates">Updates</Link>
          <Link to="/services">Services</Link>
          <Link to="/financing">Financing</Link>
        </div>
        <div>
          <a
            href={`tel:(915) 731-9881`}
            className="call-button items-center drop-shadow-md"
          >
            <div className="circle">
              <MdPhone className="phone-icon text-skin-base font-bold rounded-lg" />
            </div>
            <div className="text-skin-base font-bold rounded-lg">
              Contact Us <br></br>
              915-731-9881
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
