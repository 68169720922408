import React from "react";
import TestimonialsEntry from "./TestimonialsEntry.jsx";

const { useState, useEffect } = React;

const TestimonialsContainer = ({ business, media }) => {
  const [index, setIndex] = useState(0);

  const handleRightClick = () => {
    //set to last index of reviews "3"
    if (index < 3) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  }

  const handleLeftClick = () => {
    //set to last index of reviews "3"
    if (index > 0) {
      setIndex(index - 1);
    } else {
      setIndex(3);
    }
  }

  return (
    <div className="testimonials-container mt-20 flex flex-col bg-skin-secondary">
      <div 
        className="pointers right-pointer"
        onClick={handleRightClick}
      >❯</div>
      <div 
        className="pointers left-pointer"
        onClick={handleLeftClick}
        >❮</div>
      <div className="section mt-10 flex flex-col gap-10 mx-auto">
        <h1 className="text-skin-inverted text-4xl font-black">Testimonials</h1>
      </div>
      <div className="test-wrapper" >
        <div className="inner" style={{transform: `translateX(-${index * 100}%)`}}>
          {business.reviews && business.reviews.map((item, index) => {
            if (item.rating > 4) {
              return <TestimonialsEntry review={item} key={index} media={media}/>
            }

          })
          }
        </div>
      </div>
    </div>
  )
}

export default TestimonialsContainer;
