import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminUpdates from "./admin/AdminUpdates.jsx";
import AdminHeader from "./admin/AdminHeader.jsx";
import AdminFinancing from "./admin/AdminFinancing.jsx";
import AdminProducts from "./admin/AdminProducts.jsx";
import AdminServices from "./admin/AdminServices.jsx";

const { useState, useEffect, useRef } = React;

const Dashboard = ({
  user,
  updateUser,
  businessDetails,
  getBusinessDetails,
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("UPDATES");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/admin/check-session")
      .then((res) => {
        if (res.status === 200 && res.data) {
          updateUser(res.data);
          setLoading(false);
        } else {
          navigate("/admin");
        }
      })
      .catch((err) => {
        navigate("/admin");
      });
  }, []);

  const renderTab = () => {
    switch (selectedTab) {
      case "UPDATES":
        return (
          <AdminUpdates
            list={businessDetails.updates}
            getBusinessDetails={getBusinessDetails}
          />
        );
      case "HEADER":
        return (
          <AdminHeader
            businessDetails={businessDetails}
            getBusinessDetails={getBusinessDetails}
          />
        );
      case "PRODUCTS":
        return (
          <AdminProducts
            list={businessDetails.products}
            getBusinessDetails={getBusinessDetails}
          />
        );
      case "FINANCING":
        return (
          <AdminFinancing
            businessDetails={businessDetails}
            getBusinessDetails={getBusinessDetails}
          />
        );
      case "SERVICES":
        return (
          <AdminServices
            list={businessDetails.services}
            getBusinessDetails={getBusinessDetails}
          />
        );
    } 
  };

  return (
    <div className="dashboard">
      {loading && <p>Loading...</p>}
      {!loading && (
        <div className="">
          <p>Welcome {user}</p>
          <div className="flex gap-4">
            <div
              className={`p-2 bg-skin-secondary admin-tabs ${
                selectedTab === "UPDATES" ? "admin-tabs-active" : ""
              }`}
              onClick={() => setSelectedTab("UPDATES")}
            >
              Updates
            </div>
            <div
              className={`p-2 bg-skin-secondary admin-tabs ${
                selectedTab === "HEADER" ? "admin-tabs-active" : ""
              }`}
              onClick={() => setSelectedTab("HEADER")}
            >
              Header
            </div>
            <div
              className={`p-2 bg-skin-secondary admin-tabs ${
                selectedTab === "PRODUCTS" ? "admin-tabs-active" : ""
              }`}
              onClick={() => setSelectedTab("PRODUCTS")}
            >
              Products
            </div>
            <div
              className={`p-2 bg-skin-secondary admin-tabs ${
                selectedTab === "SERVICES" ? "admin-tabs-active" : ""
              }`}
              onClick={() => setSelectedTab("SERVICES")}
            >
              Services
            </div>
            <div
              className={`p-2 bg-skin-secondary admin-tabs ${
                selectedTab === "FINANCING" ? "admin-tabs-active" : ""
              }`}
              onClick={() => setSelectedTab("FINANCING")}
            >
              Financing
            </div>
          </div>
          {renderTab()}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
