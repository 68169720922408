import React from "react";
import ProductEntry from "./ProductEntry.jsx";
import { GrClose } from "react-icons/gr";
const { useState, useEffect } = React;

const ProductList = ({ products, type, setModal }) => {
  

  return (
    <div
    onClick={() => setModal('none')}
    className="modal">
      <div
      onClick={() => setModal('none')}
      className="modal-x">
      ✕
      </div>
      <div className="modal-content"
      onClick={(e) => e.stopPropagation()}>
      &gt;
        <div className="modal-header">
          <h1 className="text-skin-base text-4xl text-skin-inverted">{type}</h1>
        </div>
        <div className="modal-body">
          <div className="flex flex-col gap-4">
            {products.map((product, i) => (
              <ProductEntry key={i} product={product} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
