import React from "react";
import axios from "axios";

const { useState, useEffect, useRef } = React;

const AdminHeader = ({ businessDetails, getBusinessDetails }) => {
  const [loading, setLoading] = useState(true);
  const [headerImage, setHeaderImage] = useState(businessDetails.header_image);
  const [savedImages, setSavedImages] = useState([]);
  const [currentImage, setCurrentImage] = useState("");

  let imageRef = useRef();

  useEffect(() => {
    getImages();
  }, []);

  const getImages = () => {
    axios.get("/admin/header-image").then((res) => {
      setSavedImages(res.data);
      setLoading(false);
    });
  };

  const handlePhotoChange = (e) => {
    let file = e.target.files[0];
    setCurrentImage(file);
  };

  const handlePhotoUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("photo", currentImage);
    axios.post("/admin/image-upload-header", formData).then((res) => {
      getImages();
    });
  };

  return !loading ? (
    <div className="admin-page-container">
      <h1 className="text-2xl font-bold">Header Image</h1>
      <div id="header-img-container" className="updates-admin-container">
        <div className="header-image-container">
          <div className="text-xl font-bold">Current Header Image:</div>
          <div
            style={{
              backgroundImage: `url(./assets/headerPictures/${headerImage})`,
              backgroundSize: "cover",
              // backgroundPosition: 'bottom',
              boxShadow: "inset 0 0 400px rgba(0,0,0,0.8)",
            }}
            className="head bg-skin-accent relative"
          ></div>
        </div>
        <div className="saved-images-container">
          <div className="text-xl font-bold">Upload Image:</div>
          <form encType="multipart/form-data">
              <input
                className="file-input"
                type="file"
                accept=".png, .jpeg, .jpg"
                name="photo"
                ref={imageRef}
                onChange={handlePhotoChange}
              />
              <input
                className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
                type="submit"
                value="Upload"
                onClick={handlePhotoUpload}
              />
          </form>
          <div className="text-xl font-bold">Saved Images:</div>
          <div className="saved-images">
            {savedImages.map((image, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    setHeaderImage(image);
                  }}
                  style={{
                    backgroundImage: `url(./assets/headerPictures/${image})`,
                    backgroundSize: "cover",
                  }}
                  className={
                    "saved-image " + (headerImage === image ? "selected" : "")
                  }
                ></div>
              );
            })}
          </div>
        </div>
          {headerImage !== businessDetails.header_image && (
            <button
              onClick={() => {
                axios
                  .post("/admin/update-header-image", {
                    filename: headerImage,
                  })
                  .then((res) => {
                    getBusinessDetails();
                  });
              }}
              className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
            >
              Save Changes
            </button>
          )}
      </div>
    </div>
  ) : null;
};

export default AdminHeader;
