import React from "react";
import axios from "axios";
import FinancingEntry from "./FinancingEntry.jsx";

const { useState, useEffect, useRef } = React;

const AdminFinancing = ({ businessDetails, getBusinessDetails }) => {
  const [editedList, setEditedList] = useState(
    businessDetails.financing_options
  );
  const [showRevertButton, setShowRevertButton] = useState(false);
  const [newOption, setNewOption] = useState({
    type: "",
    description: "",
    plan_no: "",
    payment_factor: "",
    discount_rate: "",
    optional_restrictions: "",
  });

  const updateList = (newOption, i) => {
    let newList = [...editedList];
    newList[i] = newOption;
    setEditedList(newList);
    setShowRevertButton(true);
  };

  const handleRevert = () => {
    setEditedList(businessDetails.financing_options);
    location.reload();
  };

  const addOption = () => {
    setEditedList([...editedList, newOption]);
    setNewOption({
      type: "",
      description: "",
      plan_no: "",
      payment_factor: "",
      discount_rate: "",
      optional_restrictions: "",
    });
    setShowRevertButton(true);
  };

  const removeOption = (i) => {
    let newList = [...editedList];
    newList.splice(i, 1);
    setEditedList(newList);
    setShowRevertButton(true);
  };

  const saveChanges = () => {
    axios
      .post("/admin/update-financing", { financing_options: editedList })
      .then((res) => {
        getBusinessDetails();
        setShowRevertButton(false);
      });
  };

  return (
    <div className="admin-page-container">
      <h1 className="text-2xl font-bold">Financing</h1>
      <div id="financing-container" className="updates-admin-container">
        <div className="financing-container">
          <div className="table-container">
            <table className="shadow-inner">
              <thead>
                <tr className="bg-skin-secondary text-skin-inverted text-base ">
                  <th className="p-3 table-text text-left border-slate-50 border-2">
                    Type
                  </th>
                  <th className="p-3 table-text text-left border-slate-50 border-2">
                    Description
                  </th>
                  <th className="p-3 table-text text-left border-slate-50 border-2">
                    Plan No.
                  </th>
                  <th className="p-3 table-text text-left border-slate-50 border-2">
                    Payment Factor
                  </th>
                  <th className="p-3 table-text text-left border-slate-50 border-2">
                    Discount Rate
                  </th>
                  <th className="p-3 table-text text-left border-slate-50 border-2">
                    Optional Restrictions
                  </th>
                  <th className="apply-box p-3 table-text text-left border-slate-50 border-2"></th>
                  <th className="apply-box p-3 table-text text-left border-slate-50 border-2"></th>
                </tr>
              </thead>
              <tbody>
                {editedList &&
                  editedList.map((item, i) => (
                    <FinancingEntry
                      key={i}
                      option={item}
                      index={i}
                      getBusinessDetails={getBusinessDetails}
                      removeOption={removeOption}
                      updateList={updateList}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <table className="shadow-inner">
          <thead>
            <tr className="bg-skin-secondary text-skin-inverted text-base ">
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Type
              </th>
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Description
              </th>
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Plan No.
              </th>
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Payment Factor
              </th>
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Discount Rate
              </th>
              <th className="p-3 table-text text-left border-slate-50 border-2">
                Optional Restrictions
              </th>
              <th className="apply-box p-3 table-text text-left border-slate-50 border-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-skin-primary">
              <td className="p-3 table-text text-left">
                <div className="type-content-table">
                  <textarea
                    className="table-textarea"
                    type="textarea"
                    value={newOption.type}
                    onChange={(e) => {
                      setNewOption({
                        ...newOption,
                        type: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
              </td>
              <td className="p-3 table-text text-left">
                <div>
                  <textarea
                    className="table-textarea"
                    type="textarea"
                    value={newOption.description}
                    onChange={(e) => {
                      setNewOption({
                        ...newOption,
                        description: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
              </td>
              <td className="p-3 table-text text-left">
                <textarea
                  className="table-textarea"
                  type="textarea"
                  value={newOption.plan_no}
                  onChange={(e) => {
                    setNewOption({
                      ...newOption,
                      plan_no: e.target.value,
                    });
                  }}
                ></textarea>
              </td>
              <td className="p-3 table-text text-left">
                <textarea
                  className="table-textarea"
                  type="textarea"
                  value={newOption.payment_factor}
                  onChange={(e) => {
                    setNewOption({
                      ...newOption,
                      payment_factor: e.target.value,
                    });
                  }}
                ></textarea>
              </td>
              <td className="p-3 table-text text-left">
                <textarea
                  className="table-textarea"
                  type="textarea"
                  value={newOption.discount_rate}
                  onChange={(e) => {
                    setNewOption({
                      ...newOption,
                      discount_rate: e.target.value,
                    });
                  }}
                ></textarea>
              </td>
              <td className="p-3 table-text text-left whitespace-pre-line">
                <textarea
                  className="table-textarea"
                  type="textarea"
                  value={newOption.optional_restrictions}
                  onChange={(e) => {
                    setNewOption({
                      ...newOption,
                      optional_restrictions: e.target.value,
                    });
                  }}
                ></textarea>
              </td>
              <td className="p-3 table-text text-left">
                <a
                  target="_blank"
                  className="icon-container fin-icon add-icon"
                  onClick={() => {
                    addOption();
                  }}
                >
                  Add
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          onClick={() => {
            saveChanges();
          }}
          className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
        >
          Save Changes
        </button>
        {showRevertButton ? (
            <button
              className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
              onClick={() => {
                handleRevert();
              }}
            >
              Revert Changes
            </button>
          ) : null}
      </div>
    </div>
  );
};

export default AdminFinancing;
