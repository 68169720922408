import React from "react";
const { useState, useEffect } = React;

const UpdateCard = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setIsHovered(!isHovered);
      }}
      onMouseLeave={() => {
        setIsHovered(!isHovered);
      }}
      className={`card  bg-skin-secondary text-skin-inverted flex flex-col gap-4`}
    >
      <div className="img-container">
        {item.image !== null ? (
          <img
            className={`card-img`}
            src={
              item.image.includes("https")
                ? item.image
                : `./assets/updatePictures/${item.image}`
            }
            alt={item.title}
          />
        ) : (
          <img
            className="card-img"
            src="./assets/update-default-01.png"
            alt={item.title}
          />
        )}
      </div>

      <h3 className="pl-4 text-lg font-semibold">{item.title}</h3>
      <p className="pl-4 pr-4 text-base">{item.body}</p>
    </div>
  );
};

export default UpdateCard;
