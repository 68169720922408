import React from "react";
import axios from "axios";
import { BsFillTrashFill } from "react-icons/bs";
import { AiFillEdit, AiFillCheckCircle } from "react-icons/ai";

const { useState, useEffect, useRef } = React;

const ProductEntry = ({ product, index, removeOption, updateList }) => {
  const [editedOption, setEditedOption] = useState(product);
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <tr
      key={index}
      className={index % 2 === 0 ? "bg-skin-primary" : "bg-slate-200"}
    >
      <td className="p-3 table-text text-left">
        <div className="type-content-table">
          <textarea
            className="table-textarea"
            type="textarea"
            value={editedOption.image}
            onChange={(e) => {
              setEditedOption({ ...editedOption, image: e.target.value });
            }}
          ></textarea>
        </div>
      </td>
      <td className="p-3 table-text text-left">
        <div>
          
          <select
            className="table-select"
            value={editedOption.type}
            onChange={(e) => {
              setEditedOption({ ...editedOption, type: e.target.value });
            }}
          >
            <option value="package_unit">Package Unit</option>
            <option value="split_systems">Split Systems</option>
          </select>
        </div>
      </td>
      <td className="p-3 table-text text-left">
        <textarea
          className="table-textarea"
          type="textarea"
          value={editedOption.model}
          onChange={(e) => {
            setEditedOption({ ...editedOption, model: e.target.value.split(",") });
          }}
        ></textarea>
      </td>
      <td className="p-3 table-text text-left">
        <textarea
          className="table-textarea"
          type="textarea"
          value={editedOption.description}
          onChange={(e) => {
            setEditedOption({ ...editedOption, description: e.target.value });
          }}
        ></textarea>
      </td>
      <td className="p-3 table-text text-left">
        <textarea
          className="table-textarea"
          type="textarea"
          value={editedOption.price}
          onChange={(e) => {
            setEditedOption({
              ...editedOption,
              payment_factor: e.target.value,
            });
          }}
        ></textarea>
      </td>
      <td className="p-3 table-text text-left">
        <div
          className="icon-container fin-icon"
          onClick={() => {
            setIsEditing(!isEditing);
            updateList(editedOption, index);
          }}
        >
          <AiFillCheckCircle/>
        </div>
      </td>
      <td className="p-3 table-text text-left">
        <div
          className="icon-container fin-icon"
          onClick={() => {removeOption(index)}}
        >
          <BsFillTrashFill/>
        </div>
      </td>
    </tr>
  ) : (
    <tr
      key={index}
      className={index % 2 === 0 ? "bg-skin-primary" : "bg-slate-200"}
    >
      <td className="p-3 table-text text-left">
        <div className="type-content-table product-table-img" style={{backgroundImage: `url(./assets/productPictures/${editedOption.image})`}}></div>
      </td>
      <td className="p-3 table-text text-left">{editedOption.type}</td>
      <td className="p-3 table-text text-left">{editedOption.model.join(', ')}</td>
      <td className="p-3 table-text text-left">{editedOption.description}</td>
      <td className="p-3 table-text text-left">
        {editedOption.price}
      </td>
      <td className="p-3 table-text text-left">
        <div
          className="icon-container fin-icon"
          onClick={() => setIsEditing(!isEditing)}
        >
          <AiFillEdit/>
        </div>
      </td>
      <td className="p-3 table-text text-left">
        <div
          className="icon-container fin-icon"
          onClick={() => {removeOption(index)}}
        >
          <BsFillTrashFill/>
        </div>
      </td>
    </tr>
  );
};

export default ProductEntry;