import React from "react";
import axios from "axios";
import ServiceEntry from "./ServiceEntry.jsx";

const { useState, useEffect, useRef } = React;

const AdminServices = ({ list, getBusinessDetails }) => {
  const [editedList, setEditedList] = useState(list);

  let newHvacService = useRef();
  let newNormalService = useRef();

  const saveChanges = () => {
    axios
      .post("/admin/update-services", { services: editedList })
      .then((res) => {
        getBusinessDetails();
      });
  };

  const removeService = (i, type) => {
    let newList = { ...editedList };
    newList[type].splice(i, 1);
    setEditedList(newList);
  };

  const addService = (e, type) => {
    e.preventDefault();
    let newList = { ...editedList };
    if (type === "normal_services") {
      newList[type].push(newNormalService.current.value);
      newNormalService.current.value = "";
    } else {
      newList[type].push(newHvacService.current.value);
      newHvacService.current.value = "";
    }
    setEditedList(newList);
  };

  return (
    <div className="admin-page-container">
      <h1 className="text-2xl font-bold">Services</h1>
      <div className="admin-service-wrapper">
        <div className="service-type-container">
          <h1 className="text-2xl font-bold admin-header-services">
            Normal Services
          </h1>
          <div className="services-container">
            {editedList.normal_services.map((service, i) => {
              return (
                <ServiceEntry
                  key={i}
                  i={i}
                  type={"normal_services"}
                  removeService={removeService}
                  service={service}
                />
              );
            })}
          </div>
        </div>
        <form
          className="add-service-form"
          onSubmit={(e) => {
            addService(e, "normal_services");
          }}
        >
          <input
            className="p-2"
            type="text"
            ref={newNormalService}
            placeholder="Add Service"
          />
          <button className="add-service-button" type="submit">
            Add Normal Service
          </button>
        </form>
        <div className="service-type-container">
          <h1 className="text-2xl font-bold admin-header-services">
            HVAC Services
          </h1>
          <div className="services-container">
            {editedList.hvac_services.map((service, i) => {
              return (
                <ServiceEntry
                  key={i}
                  i={i}
                  type={"hvac_services"}
                  removeService={removeService}
                  service={service}
                />
              );
            })}
          </div>
        </div>
        <form
          className="add-service-form"
          onSubmit={(e) => {
            addService(e, "hvac_services");
          }}
        >
          <input
            className="p-2"
            type="text"
            ref={newHvacService}
            placeholder="Add Service"
          />
          <button className="add-service-button" type="submit">
            Add Hvac Service
          </button>
        </form>
        { editedList !== list &&
          <button
            onClick={() => {
              saveChanges();
            }}
            className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
          >
            Save Changes
          </button>
        }
      </div>
    </div>
  );
};

export default AdminServices;
