import React from "react";
import axios from "axios";
import AdminUpdateEntry from "./AdminUpdateEntry.jsx";

const { useState, useEffect, useRef } = React;

const AdminUpdates = ({ list, type, getBusinessDetails }) => {
  const [editedList, setEditedList] = useState(list);
  const [showRevertButton, setShowRevertButton] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);

  useEffect(() => {
    setShowRevertButton(JSON.stringify(list) !== JSON.stringify(editedList));
  }, [list, editedList]);

  const handleFormSubmit = (form) => {
    let newUpdate = {
      image: form.image.length > 0 ? form.image : null,
      title: form.title,
      body: form.body,
      date: new Date().toISOString(),
    };

    setEditedList([...editedList, newUpdate]);
  };

  const handleSave = () => {
    axios
      .post("/admin/updates", {
        newData: editedList,
        deletedItems: deletedItems,
      })
      .then((res) => {
        setDeletedItems([]);
        getBusinessDetails();
      });
  };

  const saveEdits = (i, newUpdate) => {
    let newList = [...editedList];
    newList[i] = newUpdate;
    setEditedList(newList);
  };

  const handleDelete = (i) => {
    let newList = [...editedList];
    const deletedUpdate = newList.splice(i, 1);
    setDeletedItems([...deletedItems, deletedUpdate[0]]);
    setEditedList(newList);
  };

  const handleRevert = () => {
    setEditedList(list);
    location.reload();
  };

  return (
    <div>
      <h1 className="text-2xl font-bold">Updates</h1>
      <div className="updates-admin-container">
        <div className="flex flex-col gap-4">
          {editedList.length > 0 ? (
            editedList.map((item, i) => {
              return (
                <AdminUpdateEntry
                  key={i}
                  update={item}
                  index={i}
                  saveEdits={saveEdits}
                  handleDelete={handleDelete}
                />
              );
            })
          ) : (
            <div className="update-admin">
              <h1 className="text-2xl font-bold">No updates yet...</h1>
            </div>
          )}
          <button
            className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
            onClick={() => {
              handleSave();
            }}
          >
            Save Changes
          </button>
          {showRevertButton ? (
            <button
              className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
              onClick={() => {
                handleRevert();
              }}
            >
              Revert Changes
            </button>
          ) : null}
        </div>
        <UpdateForm handleFormSubmit={handleFormSubmit} />
      </div>
    </div>
  );
};

export default AdminUpdates;

const UpdateForm = ({ handleFormSubmit }) => {
  const [currentImage, setCurrentImage] = useState("");
  const [form, setForm] = useState({
    image: "",
    title: "",
    body: "",
  });

  let imageRef = useRef();

  const handlePhotoChange = (e) => {
    let file = e.target.files[0];
    setCurrentImage(file);
  };

  const handlePhotoUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("photo", currentImage);
    axios.post("/admin/image-upload", formData).then((res) => {
      setForm({ ...form, image: res.data });
    });
  };

  return (
    <div className="update-form-container">
      <h1 className="text-2xl font-semibold">Add Update</h1>
      <div>
        <div className="admin-labels">Upload Image:</div>

        <form encType="multipart/form-data" className="flex space-between">
          <input
            className="file-input"
            type="file"
            accept=".png, .jpeg, .jpg"
            name="photo"
            ref={imageRef}
            onChange={handlePhotoChange}
          />
          <input
            className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded"
            type="submit"
            value="Upload"
            onClick={handlePhotoUpload}
          />
        </form>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit(form);
            setForm({
              image: "",
              title: "",
              body: "",
            });
            imageRef.current.value = null;
          }}
          className="update-form flex flex-col gap-4"
        >
          <div>
            <h1 className="admin-labels">Title:</h1>
            <input
              className="text-input"
              type="text"
              value={form.title}
              onChange={(e) => {
                setForm({ ...form, title: e.target.value });
              }}
            />
          </div>
          <div>
            <h1 className="admin-labels">Body:</h1>
            <textarea
              rows="5"
              cols="50"
              type="text-area"
              value={form.body}
              onChange={(e) => {
                setForm({ ...form, body: e.target.value });
              }}
            />
          </div>
          <button className="btn bg-skin-secondary text-skin-inverted btn-primary p-2 rounded">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
