import React from "react";
import UpdateCard from "./UpdateCard.jsx";

const { useEffect } = React;

const UpdatesContainer = ({ business, location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`section ${location !== '/updates' ? 'mt-10' : 'mt-28 content-wrap'} flex flex-col gap-10 mx-auto`}>
      <div className="flex flex-row items-center gap-4">
        <h1 className="text-skin-base text-4xl font-black">Updates</h1>
      </div>
      <div className="flex flex-row justify-start items-start gap-10 flex-wrap">
        {business.updates.map((item, index) => {
          return <UpdateCard item={item} key={index}/>
        })
      }
      </div>
    </div>
  );
}

export default UpdatesContainer;