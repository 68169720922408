import React from "react";
import axios from "axios";
import { BsFillTrashFill } from "react-icons/bs";
import { AiFillEdit, AiFillCheckCircle } from "react-icons/ai";

const { useState, useEffect, useRef } = React;

const FinancingEntry = ({ option, getBusinessDetails, index, updateList, removeOption }) => {
  const [editedOption, setEditedOption] = useState(option);
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <tr
      key={index}
      className={index % 2 === 0 ? "bg-skin-primary" : "bg-slate-200"}
    >
      <td className="p-3 table-text text-left">
        <div className="type-content-table">
          <textarea
            className="table-textarea"
            type="textarea"
            value={editedOption.type}
            onChange={(e) => {
              setEditedOption({ ...editedOption, type: e.target.value });
            }}
          ></textarea>
        </div>
      </td>
      <td className="p-3 table-text text-left">
        <div>
          <textarea
            className="table-textarea"
            type="textarea"
            value={editedOption.description}
            onChange={(e) => {
              setEditedOption({ ...editedOption, description: e.target.value });
            }}
          ></textarea>
        </div>
      </td>
      <td className="p-3 table-text text-left">
        <textarea
          className="table-textarea"
          type="textarea"
          value={editedOption.plan_no}
          onChange={(e) => {
            setEditedOption({ ...editedOption, plan_no: e.target.value });
          }}
        ></textarea>
      </td>
      <td className="p-3 table-text text-left">
        <textarea
          className="table-textarea"
          type="textarea"
          value={editedOption.payment_factor}
          onChange={(e) => {
            setEditedOption({
              ...editedOption,
              payment_factor: e.target.value,
            });
          }}
        ></textarea>
      </td>
      <td className="p-3 table-text text-left">
        <textarea
          className="table-textarea"
          type="textarea"
          value={editedOption.discount_rate}
          onChange={(e) => {
            setEditedOption({ ...editedOption, discount_rate: e.target.value });
          }}
        ></textarea>
      </td>
      <td className="p-3 table-text text-left whitespace-pre-line">
        <textarea
          className="table-textarea"
          type="textarea"
          value={editedOption.optional_restrictions}
          onChange={(e) => {
            setEditedOption({
              ...editedOption,
              optional_restrictions: e.target.value,
            });
          }}
        ></textarea>
      </td>
      <td className="p-3 table-text text-left">
        <div
          className="icon-container fin-icon"
          onClick={() => {
            setIsEditing(!isEditing);
            updateList(editedOption, index);
          }}
        >
          <AiFillCheckCircle/>
        </div>
      </td>
      <td className="p-3 table-text text-left">
        <div
          className="icon-container fin-icon"
          onClick={() => {removeOption(index)}}
        >
          <BsFillTrashFill/>
        </div>
      </td>
    </tr>
  ) : (
    <tr
      key={index}
      className={index % 2 === 0 ? "bg-skin-primary" : "bg-slate-200"}
    >
      <td className="p-3 table-text text-left">
        <div className="type-content-table">{editedOption.type}</div>
      </td>
      <td className="p-3 table-text text-left">{editedOption.description}</td>
      <td className="p-3 table-text text-left">{editedOption.plan_no}</td>
      <td className="p-3 table-text text-left">
        {editedOption.payment_factor}%
      </td>
      <td className="p-3 table-text text-left">
        {editedOption.discount_rate}%
      </td>
      <td className="p-3 table-text text-left whitespace-pre-line">
        {option.optional_restrictions}
      </td>
      <td className="p-3 table-text text-left">
        <div
          className="icon-container fin-icon"
          onClick={() => setIsEditing(!isEditing)}
        >
          <AiFillEdit/>
        </div>
      </td>
      <td className="p-3 table-text text-left">
        <div
          className="icon-container fin-icon"
          onClick={() => {removeOption(index)}}
        >
          <BsFillTrashFill/>
        </div>
      </td>
    </tr>
  );
};

export default FinancingEntry;
