import React from "react";
import { Rating } from 'react-simple-star-rating'

const { useState, useEffect } = React;

const TestimonialsEntry = ({ media, review }) => {

  return (
  
      <div className="test-entry">
        <div className="test-content">
          <div className="rating">
            <Rating
            initialValue={review.rating}
            allowFraction={true}
            readonly={true}
            fillColor="#63ace5"
            />
          </div>
          <div className="test-body">{review.text}</div>
          <h1 className="reviewer">- {review.author_name}</h1>
        </div>
      </div>
  )

}

export default TestimonialsEntry;