import React from "react";
import HomeCarousel from "./components/HomeCarousel.jsx";
import NavBar from "./components/NavBar.jsx";
import TestimonialsContainer from "./components/TestimonialsContainer.jsx";
import UpdatesContainer from "./components/UpdatesContainer.jsx";
import ServicesContainer from "./components/ServicesContainer.jsx";
import FinancingOptions from "./components/FinancingOptions.jsx";
import ProductsContainer from "./components/ProductsContainer.jsx";
import ProductList from "./components/ProductList.jsx";
import AdminLoginPage from "./components/AdminLoginPage.jsx";
import ContactUs from "./components/ContactUs.jsx";
import Dashboard from "./components/Dashboard.jsx";
import Footer from "./components/Footer.jsx";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import "./index.css";

const { useState, useEffect } = React;

const App = () => {
  const media = {
    isMobile: useMediaQuery({ query: "(max-width: 505px)" }),
    isMedium: useMediaQuery({ query: "(max-width: 1000px)" }),
  };

  const [user, setUser] = useState(null);

  const updateUser = (user) => {
    setUser(user);
  };

  const [businessDetails, setBusinessDetails] = useState(null);
  const [modalDisplay, setModalDisplay] = useState('none');
  const location = useLocation().pathname;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/business-details`)
      .then((response) => {
        setBusinessDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getBusinessDetails = () => {
    axios
      .get("/business-details")
      .then((response) => {
        console.log(response);
        setBusinessDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setModal = (display) => {
    setModalDisplay(display);
  };

  const getModal = () => {
    switch(modalDisplay) {
      case 'split_systems':
        return (
          <ProductList type={'Split Systems'} setModal={setModal} products={businessDetails.products.filter((item) => item.type === 'split_systems')}/>
          )
      case 'package_units':
        return (
          <ProductList type={'Package Units'} setModal={setModal} products={businessDetails.products.filter((item) => item.type === 'package_unit')}/>
          )
      default:
        return null;
    }
  }

  return !!businessDetails ? (
    <div>
      {getModal()}
      <NavBar media={media} />
      <Routes>
        <Route
          path="/"
          element={<Home businessDetails={businessDetails} media={media} setModal={setModal} location={location} />}
        />
        <Route path="/updates" element={<UpdatesContainer business={businessDetails} location={location}/>} />
        <Route
          path="/services"
          element={
            <ServicesContainer business={businessDetails} media={media} location={location}/>
          }
        />
        <Route
          path="/contact"
          element={<ContactUs business={businessDetails} location={location}/>}
        />
        <Route
          path="/financing"
          element={<FinancingOptions business={businessDetails} location={location} media={media}/>}
        />
        <Route path="/admin" element={<AdminLoginPage updateUser={updateUser}/>} />
        <Route path="/dashboard" element={<Dashboard user={user} businessDetails={businessDetails} getBusinessDetails={getBusinessDetails} updateUser={updateUser}/>} />
      </Routes>
      <Footer media={media}/>
    </div>
  ) : null;
};

const Home = ({ media, businessDetails, setModal }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-container">
      <div className="content-wrap">
        <HomeCarousel business={businessDetails} media={media} />
        <UpdatesContainer business={businessDetails}/>
        <TestimonialsContainer business={businessDetails} media={media} />
        <ProductsContainer products={businessDetails.products} setModal={setModal} media={media} />
        <ServicesContainer business={businessDetails} media={media} />
        <ContactUs business={businessDetails} />
      </div>
    </div>
  );
};

export default App;
