import React from "react";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import { MdLocationPin, MdPhone, MdOutlineAccessTime } from "react-icons/md";

import { useLocation } from "react-router-dom";

const { useState, useEffect } = React;

const ContactUs = ({ business, location }) => {
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  // });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const center = {
    lat: business.geometry.location.lat,
    lng: business.geometry.location.lng,
  };


  return (
    <div className={`section ${location !== '/contact' ? 'mt-10' : 'mt-28 content-wrap'} flex flex-col gap-10 mx-auto`}>
      <h1 className="text-skin-base text-4xl font-black">Contact Us</h1>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-4 flex-wrap">
          <div className="map-container">
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            >
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "500px" }}
                zoom={17}
                center={center}
                // onLoad={map => {
                //   const bounds = new window.google.maps.LatLngBounds();
                //   map.fitBounds(bounds);
                // }}
              >
                <Marker position={center}></Marker>
              </GoogleMap>
            </LoadScript>
          </div>
          <div className="contact-info flex flex-col gap-4">
            <div className="contact-entry">
              <MdLocationPin />
              <h3 className="font-semibold text-xl">
                {business.formatted_address}
              </h3>
            </div>
            <div className="contact-entry">
              <MdPhone />
              <h3 className="font-semibold text-xl">
                {business.formatted_phone_number}
              </h3>
            </div>
            <div className="contact-entry">
              <MdOutlineAccessTime />
              <h3 className="font-semibold text-xl">Hours:</h3>
            </div>
            <div className="hours flex flex-col gap-2">
              {business.opening_hours.weekday_text.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
