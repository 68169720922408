import React from "react";
import {Link} from "react-router-dom";

const { useState, useEffect } = React;

const NavBar = ({ media }) => {

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const [expanded, setExpanded] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  useEffect(() => {
    if (expanded) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [expanded]);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return media.isMobile ? (
    <div 
    className={`${expanded ? 'nav-expanded' : ''} nav navButton fixed z-10 ml-5 bg-skin-secondary rounded-2xl`}
    style={{ top: visible ? '20px' : '-120px' }}
    onClick={() => {
      if (!expanded) handleClick()
    }}
    >
      <div className="menu-icon" 
      onClick={() => {
        if (expanded) handleClick()
      }}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><defs></defs><title>Untitled-1</title><rect className="cls-1" width="72" height="15" rx="7.5"/><rect className="cls-1" y="29" width="72" height="15" rx="7.5"/><rect className="cls-1" y="57" width="72" height="15" rx="7.5"/></svg>
      </div>
      {
      expanded ? (
          <div className="expanded-options">
            <Link to="/" onClick={handleClick}>Home</Link>
            <Link to="/updates" onClick={handleClick}>Updates</Link>
            <Link to="/services" onClick={handleClick}>Services</Link>
            <Link to="/financing" onClick={handleClick}>Financing</Link>
            <Link to="/contact" onClick={handleClick}>Contact</Link>  
          </div>
        ) : null
      }
    </div>
  ) : (
    <div 
    className="nav flex w-screen justify-center fixed z-10 text-skin-inverted"
    style={{ top: visible ? '20px' : '-70px' }}
    >
      <div 
      className="flex justify-between text-skin-inverted bg-skin-secondary gap-10 px-5 font-bold py-2 rounded-3xl content-center h-10 drop-shadow-lg">
        <Link to="/">Home</Link>
        <Link to="/updates">Updates</Link>
        <Link to="/services">Services</Link>
        <Link to="/financing">Financing</Link>
        <Link to="/contact" >Contact</Link>
      </div>
    </div>
  );
};

export default NavBar;
